import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { register, resendOtp, verifyEmail } from "../../../redux/auth/thunk";
import MyForm from "../../../Component/formComponent";
import { signinRoute } from "../../../routes/routesPath";
import { genderOptions } from "../../../utils/constants";
import { otpValidationSchema, registrationSchema } from "../../../utils/validationSchemas";
import SideSection from "../components/sideSection";
import { useFormik } from "formik";
import { successMsg } from "../../../utils/toastMessage";
import { handleTransformValues } from "../../../utils/formatersAndParsers";

export const registrationFields = [
  {
    value: "name",
    type: "text",
    placeholder: "Enter full name",
    label: "Full Name",
    isRequired: true,
  },
  { value: "email", type: "email", isRequired: true },
  {
    value: "mobile",
    type: "phone",
    isRequired: true,
  },
  // { value: "birth_date", type: "date" },
  // { value: "gender", type: "select", options: genderOptions },
  // { value: "address", type: "text" },
  { value: "avatar", type: "file" },
  { value: "password", type: "password", isRequired: true },
  { value: "confirmPassword", type: "password", isRequired: true },
];

const defaultValues = {
  name: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
};

const Signup = () => {
  const navigate = useNavigate();
  const [registrationStep, setRegistrationStep] = useState({ step: 1 });
  const [otpDetails, setOtpDetails] = useState({});

  const handleRegistration = async () => {
    try {
      setRegistrationStep((prev) => ({ ...prev, action: "registering" }));

      const request = await handleTransformValues(values);
      const response = await handleApiRequest(register, request);

      if (response.status) {
        setRegistrationStep((prev) => ({ ...prev, action: "", step: 2 }));
        setOtpDetails((prev) => ({ ...prev, email: values.email }));
      } else {
        setRegistrationStep((prev) => ({ ...prev, action: "" }));
      }
    } catch (error) {
      console.log("error", error);
      setRegistrationStep((prev) => ({ ...prev, action: "" }));
    }
  };

  const handleEmailVerification = async () => {
    setRegistrationStep((prev) => ({ ...prev, action: "verifyingEmail" }));

    const request = {
      email: otpDetails.email,
      otp: otpForm.values.otp,
    };

    const response = await handleApiRequest(verifyEmail, request);
    if (response.status) {
      navigate(signinRoute);
    }
    setRegistrationStep((prev) => ({ ...prev, action: "" }));
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue, setSubmitting, isSubmitting } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: defaultValues,
      validationSchema: registrationSchema,
      onSubmit: handleRegistration,
    });

  const otpForm = useFormik({
    initialValues: { otp: "" },
    validationSchema: otpValidationSchema,
    onSubmit: () => {
      handleEmailVerification();
    },
  });

  const handleResendOtp = async () => {
    setRegistrationStep((prev) => ({ ...prev, action: "sendingOtp" }));

    const response = await handleApiRequest(resendOtp, { email: otpDetails.email });
    if (response.status) {
      successMsg("OTP sent!!");
    }
    setRegistrationStep((prev) => ({ ...prev, action: "" }));
  };

  return (
    <section className="position-relative">
      <div className="">
        <Row className="authContainer m-0">
          <SideSection />
          <Col lg={6} className="authFormContainer p-0">
            <div className="my-5 w-100">
              <div className="pointer text-center" onClick={() => navigate("/")}>
                <img
                  src="/mainLogo.png"
                  className="w-100 object-fit-cover"
                  style={{ height: 160 }}
                />
              </div>
              <div className="position-relative mt-3 mx-3">
                {registrationStep?.step === 1 ? (
                  <Form className="signup_form inputall_same" onSubmit={handleSubmit}>
                    <MyForm
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      formFields={registrationFields}
                      setSubmitting={setSubmitting}
                    />

                    {registrationStep?.action === "registering" ? (
                      <button className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center">
                        Creating your account
                        <i className="growLoader spinner-grow ms-2" role="status"></i>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="primaryBtn rounded w-100"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            Uploading your avatar
                            <i className="growLoader spinner-grow ms-2" role="status"></i>
                          </>
                        ) : (
                          "Sign Up"
                        )}
                      </button>
                    )}

                    <div className="small py-4">
                      <p>
                        Already have an account?
                        <Link to={signinRoute} className="text-decoration-none">
                          {" "}
                          Login
                        </Link>
                      </p>
                    </div>
                  </Form>
                ) : registrationStep?.step === 2 ? (
                  <>
                    <div>
                      <p
                        className="backBtn"
                        onClick={() => setRegistrationStep((prev) => ({ ...prev, step: 1 }))}
                      >
                        <IoIosArrowBack />
                      </p>
                      <h4>Email Verification</h4>
                    </div>

                    <p>
                      We have sent a 6 digits OTP on your email. Enter the OTP to verify your email
                      address.
                    </p>

                    <Form className="signup_form inputall_same" onSubmit={otpForm.handleSubmit}>
                      <MyForm
                        values={otpForm.values}
                        setFieldValue={otpForm.setFieldValue}
                        errors={otpForm.errors}
                        handleChange={otpForm.handleChange}
                        formFields={[
                          { value: "otp", label: "OTP", placeholder: "Enter OTP", type: "text" },
                        ]}
                      />

                      {registrationStep?.action === "verifyingEmail" ? (
                        <button className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center mt-3">
                          Verifying your Email
                          <i className="growLoader spinner-grow ms-2" role="status"></i>
                        </button>
                      ) : (
                        <button type="submit" className="primaryBtn rounded w-100 mt-3">
                          Verify Email
                        </button>
                      )}
                    </Form>

                    <div className="small py-4">
                      <p>
                        Didn't receive OTP?
                        {registrationStep.action === "sendingOtp" ? (
                          <button className="textBtn text-primary"> Sending OTP...</button>
                        ) : (
                          <button className="textBtn text-primary" onClick={handleResendOtp}>
                            {" "}
                            Resend
                          </button>
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import banner from "../../assets/images/homeBanner.jpg";
import storyImage from "../../assets/images/storyImage.png";
import StoryCard from "../../Component/storyCard/homeStoryCard";
import { handleApiRequest } from "../../services/handleApiRequest";
import Slider from "react-slick";
import { getMainStoriesList } from "../../redux/stories/thunk";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export default function Home() {
  const { categories = [] } = useSelector((state) => state.common || {});

  const crouselSettings = {
    countPerRow: 2,
    leftIconClassName: "homeCrouselLeftIcon",
    rightIconClassName: "homeCrouselRightIcon",
    responsive: [
      { breakPoint: 576, countPerRow: 2 },
      { breakPoint: 768, countPerRow: 3 },
      { breakPoint: 992, countPerRow: 4 },
    ],
  };

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 3,
    responsive: [
      { breakpoint: 576, settings: { slidesPerRow: 1 } },
      { breakpoint: 950, settings: { slidesPerRow: 2 } },
      { breakpoint: 1200, settings: { slidesPerRow: 3 } },
    ],
  };

  const [storiesList, setStoriesList] = useState({});

  const handleStoriesList = async (category) => {
    const categoryId = categories?.find((cat) => cat.name === category)?._id;

    const request = { limit: 30, category: categoryId };
    const response = await handleApiRequest(getMainStoriesList, request);

    if (response.status) {
      setStoriesList((prev) => ({ ...prev, [category]: response.data }));
    }
  };

  useEffect(() => {
    if (categories?.length > 0) {
      handleStoriesList("Happy");
      handleStoriesList("Love");
      handleStoriesList("Angry");
      handleStoriesList("Emotional");
    }
  }, [categories]);

  return (
    <>
      <Helmet>
        <title>FeelingHub | Home</title>
      </Helmet>
      <section className="home">
        <div className="homeBanner d-flex m-0 pb-5 px-lg-5 px-4">
          <div className="d-flex align-items-center justify-content-center">
            <div className="px-sm-5">
              <h3 className="mb-3">
                Need someone to talk to?
                <span className="text-danger text-decoration-underline"> Join Us</span>
              </h3>
              <p className="bannerDesc">
                Feelinghub is a unique platform where you can share your feelings, emotions, and
                problems with others, either anonymously or with your identity revealed. Receive
                support from our community or our admin team. Explore our programs and sign up now!
              </p>
            </div>
          </div>
          <div className="d-none d-md-flex align-items-center justify-content-center text-center">
            <img src={banner} className="img-fluid" style={{ maxHeight: 250 }} />
          </div>
        </div>

        <div className="emotionalInsights px-2 px-lg-0">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="border-bottom-strong w-fit pt-4 mb-2">Emotional Insights</h3>
          </div>

          <div className="rounded p-0 p-lg-4 bg-white" style={{ border: "1px solid #cdcdcd" }}>
            <Slider {...settings}>
              {Array.from({ length: 30 }).map((item, i) => (
                <div className="my-2">
                  <StoryCard index={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="emotionalInsights px-2 px-lg-0">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="homeStroiesHeading w-fit pt-4 mb-2">Happy Vibes</h3>
          </div>

          <div className="rounded p-0 p-lg-4 bg-white" style={{ border: "1px solid #cdcdcd" }}>
            <Slider {...settings}>
              {Array.from({ length: 20 }).map((item, i) => (
                <div className="my-2">
                  <StoryCard index={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="emotionalInsights px-2 px-lg-0">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="homeStroiesHeading w-fit pt-4 mb-2">Love Birds</h3>
          </div>

          <div className="rounded p-0 p-lg-4 bg-white" style={{ border: "1px solid #cdcdcd" }}>
            <Slider {...settings}>
              {Array.from({ length: 20 }).map((item, i) => (
                <div className="my-2">
                  <StoryCard index={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="emotionalInsights px-2 px-lg-0">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="homeStroiesHeading w-fit pt-4 mb-2">Angry Fairies</h3>
          </div>

          <div className="rounded p-0 p-lg-4 bg-white" style={{ border: "1px solid #cdcdcd" }}>
            <Slider {...settings}>
              {Array.from({ length: 20 }).map((item, i) => (
                <div className="my-2">
                  <StoryCard index={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div> */}

        <div className="ourMission my-5 pt-5 px-3 px-lg-5">
          <Row className="align-items-center">
            <Col md={6} className="pe-lg-5 pe-3">
              <div className="content">
                <h2 className="border-bottom-strong w-fit mb-3">Our Mission</h2>
                <p className="bannerDesc mb-5">
                  Welcome to Feelinghub, where we believe in the power of sharing emotions and
                  experiences. Founded in 2023, our innovative Community Service Program aims to
                  inspire individuals to open up, connect, and grow. Join us on a journey of
                  self-discovery and meaningful connections.
                </p>
              </div>
            </Col>
            <Col md={6} className="d-flex justify-content-center">
              <img src={storyImage} className="img-fluid w-100" />
            </Col>
          </Row>
        </div>

        <div className="ourStory px-3 px-lg-5 my-5">
          <Row className="align-items-center">
            <Col md={6} className="d-flex justify-content-center">
              <img src={storyImage} className="img-fluid w-100" />
            </Col>
            <Col md={6} className="px-lg-5 px-3 mt-3">
              <h2 className="border-bottom-strong w-fit mb-3">Our Story</h2>
              <p className="bannerDesc p-0">
                Established in 2023 to meet the community's growing needs, Feelinghub is a
                distinctive Community Service Program providing a fulfilling and interactive
                experience for all participants. We embrace our responsibilities by offering
                inclusive programs accessible to individuals from diverse backgrounds, constantly
                working towards our mission.
              </p>
            </Col>
          </Row>
        </div>

        {/* <div className="curvatureContainer lowerCurvatureContainer">
          <div>
            <div id="curved-corner-bottomright" className="curvedCorner" />
          </div>
          <div>
            <div id="curved-corner-bottomleft" className="curvedCorner" />
          </div>
        </div> */}
      </section>
    </>
  );
}

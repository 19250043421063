import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { IoIosShareAlt } from "react-icons/io";
import storyImage from "../../assets/images/storyImage.png";
import { conatctUsSchema } from "../../utils/validationSchemas";
import { successMsg } from "../../utils/toastMessage";
import { contactUsReason } from "../../utils/constants";
import { addQuery } from "../../redux/queries/thunk";
import { handleApiRequest } from "../../services/handleApiRequest";
import MyForm from "../../Component/formComponent";
import ShareBtn from "../../Component/common/shareBtn";

export const profileUpdateFields = [
  {
    value: "name",
    type: "text",
    placeholder: "Enter full name",
    label: "Full Name",
  },
  { value: "mobile", type: "phone" },
  { value: "email", type: "email" },
  { value: "reason", type: "select", options: contactUsReason },
  { value: "comment", type: "textarea" },
];

const defaultValues = {
  name: "",
  mobile: "",
  email: "",
  reason: "",
  comment: "",
};

const ContactUs = () => {
  const { userProfile } = useSelector((state) => state.auth);

  const handleSubmitQuery = async () => {
    const response = await handleApiRequest(addQuery, values);
    resetForm();

    if (response.status) {
      successMsg("Thanks for your response");
    }
  };

  let {
    values,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: defaultValues,
    validationSchema: conatctUsSchema,
    onSubmit: handleSubmitQuery,
  });

  useEffect(() => {
    if (userProfile._id) {
      const { name, mobile, email } = userProfile;
      setValues({ ...defaultValues, name, mobile, email });
    }
  }, [userProfile]);

  return (
    <section className="contactusWrapper position-relative">
      <Row className="bg-secondary bg-white mx-0 my-3 shadow py-4 px-3 px-lg-5">
        <Col md={7}>
          <div className="d-flex align-items-center justify-content-center justify-content-md-start h-100">
            <div>
              <h4 className="text-uppercase text-center">We are waiting to hear from you.</h4>
              <p className="m-0 d-none d-md-block">Need to get in touch with us?</p>
              <p className="d-none d-md-block">Fill the form with you inquiry/feedback</p>
            </div>
          </div>
        </Col>
        <Col md={5} className="bg-white rounded">
          <div className="mx-auto my-4">
            <Form className="" onSubmit={handleSubmit}>
              <MyForm
                formFields={profileUpdateFields}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                handleChange={handleChange}
              />

              {isSubmitting ? (
                <button
                  className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center"
                  disabled
                >
                  Submitting
                  <i className="growLoader spinner-grow ms-2" role="status" />
                </button>
              ) : (
                <button type="submit" className="primaryBtn rounded w-100" disabled={isSubmitting}>
                  Submit
                </button>
              )}
            </Form>
          </div>
        </Col>
      </Row>
      <div className="ourMission py-5 px-3 px-lg-5">
        <Row className="align-items-center">
          <Col md={6} className="">
            <div className="content">
              <h2 className="">Help People like you</h2>
              <p className="bannerDesc my-4">
                In an age where technology dominates our daily lives. We are dedicated to provide
                support and resources for those struggling with mental health issues, relationship
                problems, or other challenges by bringing people together. By sharing these
                platforms with more individuals, we can expand the reach of these communities and
                ensure that no one feels isolated or alone in their struggles.
                <br />
                <ul>
                  <li>Share the community with the people in need.</li>
                  <li>Donate us to reach more people like you.</li>
                </ul>
              </p>
            </div>
            <div className="d-flex align-items-center gap-10 mb-4">
              <ShareBtn />
            </div>
          </Col>
          <Col md={6} className="">
            <div className="d-flex justify-content-center">
              <ul>
                <li>About Us</li>
                <li>Privacy policies</li>
                <li>Terms and condition</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ContactUs;

import { createSlice } from "@reduxjs/toolkit";
import { getMainStoriesList } from "./thunk";

const initialState = {
  loadingStoriesList: false,
  storiesList: {},
  myStoriesList: {},
  privateStoriesList: {},
};

const slice = createSlice({
  name: "stories",
  initialState: { ...initialState },
  reducers: {
    updateStoriesList: (state, action) => {
      const { listFor } = action.payload || {};
      if (listFor === "private") {
        state.privateStoriesList = action.payload;
      } else if (listFor === "user") {
        state.myStoriesList = action.payload;
      } else {
        state.storiesList = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainStoriesList.pending, (state, action) => {
        state.loadingStoriesList = true;
      })
      .addCase(getMainStoriesList.fulfilled, (state, action) => {
        state.loadingStoriesList = false;
        const { listFor } = action.payload || {};

        if (listFor === "user") {
          state.myStoriesList = action.payload?.data || {};
        } else if (listFor === "private") {
          state.privateStoriesList = action.payload?.data || {};
        } else {
          state.storiesList = action.payload?.data || {};
        }
      })
      .addCase(getMainStoriesList.rejected, (state, action) => {
        state.loadingStoriesList = false;
      });
  },
});

export default slice.reducer;
export const { updateStoriesList } = slice.actions;

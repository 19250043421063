import React from "react";
import Skeleton from "react-loading-skeleton";

export default function CardSkeleton() {
  return (
    <>
      <div className="storyCard pointer border-bottom border-white py-4">
        <div className="d-flex align-items-center">
          <Skeleton circle className="img-medium" baseColor="#d3d3d3" highlightColor="#fff" />

          <div className="ms-2">
            <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
            <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
          </div>
        </div>

        <Skeleton baseColor="#d3d3d3" highlightColor="#fff" className="my-3" height={28} />
        <Skeleton count={3} baseColor="#d3d3d3" highlightColor="#fff" />
      </div>
    </>
  );
}

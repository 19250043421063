import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import SubHeader from "../Component/Header/subHeader";
import SideBarSection from "../Pages/myAccount/sideBarSection";

export default function MyAccountTheme() {
  return (
    <div className="mainWrapper">
      <Header />
      <main className="mainBody">
        <section className="storiesListContainer">
          <div className="subHeaderContainer bg-primary pb-1">
            <SubHeader />
          </div>

          <div className="profileContainer border-bottom border-white d-flex ">
            <SideBarSection />
            <Outlet />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { BsFlagFill } from "react-icons/bs";
import { storyDetailsRoute } from "../../routes/routesPath";
import ReportStoryPop from "../Modals/reportStoryPop";
import { handleLoginPop } from "../../utils/helpers";
import useAuth from "../../hooks/useAuth";
import { handleApiRequest } from "../../services/handleApiRequest";
import AddReaction from "../common/addReaction";
import StoryCardHeader from "./storyCardHeader";
import { manageBookmark } from "../../redux/bookmarks/thunk";

export default function StoryCard({ story = {}, showReaction = "", setShowReaction }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [userAction, setUserAction] = useState(null);
  const [storyDetails, setStoryDetails] = useState({ ...story });

  const handleBookmark = async (e) => {
    e.stopPropagation();
    if (!isAuthenticated) return handleLoginPop(true);

    setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));

    const response = await handleApiRequest(manageBookmark, { storyId: storyDetails._id });
    if (!response.status) {
      setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));
    }
  };

  return (
    <>
      <div
        className="storyCard pointer border-bottom border-white py-4"
        onClick={() => navigate(`${storyDetailsRoute}/${storyDetails._id}`)}
      >
        <StoryCardHeader story={storyDetails} showStatus={false} />

        <h4 className="my-3">{storyDetails.title}</h4>
        <div
          className="storyDesc multiline-truncate"
          dangerouslySetInnerHTML={{ __html: storyDetails.description }}
        />

        <div className="storyAction d-flex align-items-center justify-content-between my-3 pt-3">
          <div className="d-flex align-items-center">
            <p className="">
              <span dangerouslySetInnerHTML={{ __html: storyDetails.category?.iconFilled }} />
              <span className="d-none d-lg-inline-block">{storyDetails.category?.name}</span>
            </p>
            <div className="position-relative">
              <AddReaction
                showReaction={showReaction}
                setShowReaction={setShowReaction}
                savedReaction={storyDetails.myReaction}
                reactionsCount={storyDetails?.reactionsCount}
                storyId={storyDetails._id}
                setStoryDetails={setStoryDetails}
              />
            </div>
            <p onClick={handleBookmark}>
              {storyDetails.isBookmarked ? (
                <>
                  <span>
                    <FaBookmark style={{ height: 20 }} />
                  </span>
                  <span className="d-none d-lg-inline-block">Unsave</span>
                </>
              ) : (
                <>
                  <span>
                    <FaRegBookmark style={{ height: 20 }} />
                  </span>
                  <span className="d-none d-lg-inline-block">Save</span>
                </>
              )}
            </p>
          </div>
          <div>
            <p
              className="d-flex align-items-center"
              onClick={(e) => {
                e.stopPropagation();
                setUserAction({ action: "reportStory", id: storyDetails._id });
              }}
            >
              <span className="d-none d-lg-inline-block"> Report</span>
              <BsFlagFill style={{ height: 20, width: 20 }} />
            </p>
          </div>
        </div>
      </div>

      {userAction?.action === "reportStory" && (
        <ReportStoryPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
